@import '../../_global/variables';

$section_height: 300px;

.PageHeroHeader {
    background-color: $primary-color;
    width: 100vw;
    height: $section_height;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    &::before {
        content: "";
        display: block;
        height: $section_height;
        background: $background-color;
        width: 100%;
        position: absolute;
        opacity: .5;
    }

    h1 {
        z-index: 10;
    }
}

.PageHeroHeaderFixer {
    height: $section_height;
    margin-bottom: $padding-lv5;
}