@import '../../_global/variables';

.ServiceLink {

    text-decoration: none;

    .AvatarGrayScale {
        opacity: 0.8;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;

        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
        }
    }

    .ServiceTitle {
        color: $text-color;
    }
}