.fullWidth {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero::before {
    content: '';
    display: block;
    background: url("../../assets/images/homepage-hero.webp") no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    opacity: 0.3;
}

.highlighted {
    background-color: #DAA520;
    color: white;
    padding: 3px 5px;
}