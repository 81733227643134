@import '../../_global/variables';
.Team {
    .SocialLinks{
        text-align: center;

        a{
            background-color: $primary-color;
            color:white;
            border-radius: 3px;
            padding: 2px 4px;
            margin: $padding-lv1;
        }
    }
}